<template>
  <div class="about">
    <!--START Principal-->
    <section id="home-background" class="main-home pt-5">
      <div class="container">
          <div class="row">
              <div class="col-lg-7">
                  <div class="p-4"></div>
                  <h2 class="w3-text-white w3-jumbo"><span style="background:rgba(88, 88, 88, 0.2);">We are a professional team with qualified human talent</span></h2>
                  <router-link class="w3-button w3-blue w3-hover-light-gray p-3" to="/contact">Contact Us Now!</router-link>
                  <router-link class="w3-button w3-indigo w3-hover-light-gray p-3" to="/games">Our Games</router-link>
                  <div class="p-4"></div>
              </div>
          </div>
      </div>
    </section>
    <!--END Principal-->
    <!--START CONTENT ABOUT-->
    <section class="bw-color-1 w3-text-white pt-5 pb-5">
      <div class="container">
        <div class="row">
            <div class="col-sm-12 pb-4">
                <div class="text-center">
                    <figure style="max-width:150px; margin:0 auto;"><img src="@/assets/img/logo_lobo.png" class="w3-image" /></figure>
                </div>
                <p class="text-center">We are a group of developers united by the passion and creation of innovative, fun and functional ideas, where the goal is to impress, satisfy and solve the problems of our users, whether by our games or smart solutions for a specific project.</p>
            </div>
            <div class="col-sm-6 text-center">
                <p class="w3-xxlarge">
                    Services
                    <span class="w3-block"><i class="fa fa-cubes" aria-hidden="true"></i></span>
                </p>
                <p>We strive to provide quality and innovative services to each of our partners, that is why our responsibility and professionalism are our virtue to achieve the objectives with each product delivered.</p>
            </div>
            <div class="col-sm-6 text-center">
                <!--image-->
                <img src="@/assets/img/imgsolucion.jpg" class="w3-image" style="width:500px; margin:0 auto;" />
            </div>
        </div>
      </div>
    </section>
    <section class="bg-content2 w3-white pb-5 position-relative">
      <div class="container">
        <div class="row">
            <div class="col-sm-12 p-5"></div>
            <div class="col-sm-6">
              <!--image-->
              <img src="@/assets/img/world.svg" class="w3-image" style="width:500px;" />
            </div>
            <div class="col-sm-6" style="border: 2px solid #2b78f7; border-radius: 0.4em;">
                <h1 class="w3-center w3-text-light-gray bw-fonts-archivo-black" style="font-style: italic; background: #131d2c; border-radius: 0.2em;">OUR <span class="w3-text-blue">MISSION</span></h1>
                <p class="w3-xxlarge w3-text-blue bw-fonfs-kanit" style="margin:0 5px; font-style: italic;">First!</p>
                <p>We are a group of developers united by the passion and creation of innovative, fun and functional ideas, where the goal is to impress, satisfy and solve the problems of our users, either by our games or/or smart solutions for A project specifically..</p>
                <p class="w3-xxlarge w3-text-blue bw-fonfs-kanit" style="margin:0 5px; font-style: italic;">Second!</p>
                <p>We also ensure that each creation by the team has its unique, original touch, highlighted by the rest of our main competitors and demonstrate the talent obtained by each of our team members. We want each one of our players or users to get the best experience by using our products and services.</p>
                <h1 class="font-weight-bold w3-text-indigo">Our Vision</h1>
                <p>To be recognized as a worldwide developer, as a company dedicated to digital entertainment and technological solutions. Represent the entire video game community with our products from anywhere.</p>
            </div>
        </div>
      </div>
    </section>
    <!--END CONTENT ABOUT-->
  </div>
</template>
<script>
export default {
  components:{}
}
</script>
<style>
.main-home{ background:url('../assets/img/backnosotros.jpg'); background-size:cover; background-repeat:no-repeat; }
.bg-content2::before { content:''; opacity:0.4; position:absolute; width:100%; height: 100%; background:url('../assets/img/bgmain2.jpg'); background-size: cover; }
</style>